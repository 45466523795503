// Here you can add other styles
.btn-outline-warning {
    color: #ec971f;
    background-color: transparent;
    background-image: none;
    border-color: #ec971f;

    &:hover {
        color: #fff;
        background-color: #ec971f;
    }

    &.focus, &:focus {
        color: #fff !important;
        background-color: #ec971f !important;
    }

    &.active, &:active {
        color: #fff !important;
        background-color: #ec971f !important;
    }
}

.DateRangePicker_picker{
    z-index: 999 !important;
}


@media screen and (min-width: 1401px) {
  h3.responsive-label-text {
    font-size: 28px;
  }
}

@media screen and (max-width: 1400px) {
  h3.responsive-label-text {
    font-size: 3vw;
  }
}

@media screen and (min-width: 751px) {
  .responsive-label-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 750px) {
  .responsive-label-text {
    font-size: 3.5vw;
  }
}

.card {
  z-index: 1;
}

.stat-card {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  width: 150px;
  height: 100px;
  -webkit-box-shadow: 0px 4px 0px 0px rgba(237, 237, 237, 1);
  -moz-box-shadow: 0px 4px 0px 0px rgba(237, 237, 237, 1);
  box-shadow: 0px 4px 0px 0px rgba(237, 237, 237, 1);
  @media (max-width: 450px) {
      width: 75vw;
  }
  .card-body {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      // margin: 35px 0 39px 20%;
      @media (min-width: 500px) {
          // margin: 35px 0 39px 16%;
      }

      .stat-card__icon--green {
          background-color: #00b24c;
          border-radius: 50%;
          width: 65px;
          height: 65px;
          display: flex;
          margin-right: 10px;
      }

      .stat-card__icon--red {
          background-color: #d83f1e;
          border-radius: 50%;
          width: 65px;
          height: 65px;
          display: flex;
          margin-right: 12px;
      }

      i {
          margin: auto;
          text-align: center;
          color: white;
          font-size: 2.5em;
      }

      .stat-card__content {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          h2 {
              font-size: 20px;
              color: #666666;
          }
          h4 {
              font-size: 12px;
              color: #666666;
          }
      }
  }
}
